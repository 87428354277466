import React from "react";
import "../../shared/common/Style.css";
import bar_chart from "../../../assets/website/alia_icon_chart.svg";
import checklist from "../../../assets/website/alia_icon_checklist.svg";
import profile from "../../../assets/website/profile_icon_invert.svg";
import progress from "../../../assets/website/progress_line.svg";
import laptop from "../../../assets/website/laptop.svg";
import { useNavigate } from "react-router-dom";

const StepFlow = () => {
    const navigate = useNavigate();
    const steps = [
        {
            icon: bar_chart,
            title: "STEP 1: APPLY FOR AN ALIA ACCOUNT",
            content:
                "The journey to your goal score starts when you apply for an Alia " +
                "account! As part of this application, you'll need to fill out your " +
                "personal information, details about your upcoming test, and schedule " +
                "a 15-minute meeting with one of our team members.",
        },
        {
            icon: checklist,
            title: "STEP 2: MEET WITH THE ALIA TEAM",
            content:
                "In our 15-minute meeting, we'll discuss where you are on your study " +
                "journey, your goals for test day, and whether the Alia SAT bootcamp " +
                "will be an effective solution for you.",
        },
        {
            icon: laptop,
            title: "STEP 3: SUBSCRIBE AND TAKE DIAGNOSTIC",
            content:
                "If we have the availability to take on new students and you're able " +
                "to commit to the Alia Bootcamp, congratulations! We'll send you " +
                "a subcription link and login information, and then you'll be able to " +
                "access our platform and take an initial diagnostic problem set.",
        },
        {
            icon: profile,
            title: "STEP 4: MEET WITH AN EXPERT TUTOR",
            content:
                "In your first meeting with an expert tutor, we'll combine what we " +
                "know about you from your initial interview and your diagnostic " +
                "problem set to create a personalized roadmap tailored to your needs. " +
                "As an Alia learner, you'll be able to meet with an expert " +
                "tutor weekly.",
        },
        {
            icon: progress,
            title: "STEP 5: HIT YOUR GOAL SCORE!",
            content:
                "With Alia's structured approach, personalized attention, and your " +
                "commitment, you're well on your way to achieving your goal score. " +
                "Celebrate your success and start applying to your dream college!",
        },
    ];

    const STEP_HEIGHT = 300;
    const STEP_OFFSET = 145;

    return (
        <div className="as-container" style={{ backgroundColor: "#FFF" }}>
            <div className="as-content" style={{ color: "#016dea" }}>
                <div className="as-title" style={{ color: "#016dea" }}>
                    How It Works
                </div>
                <div className="sf-flex-container">
                    <div className="sf-arrow-column hide-on-mobile-800">
                        {steps.map(
                            (_, index) =>
                                index % 2 !== 1 &&
                                index > 0 && (
                                    <div
                                        key={index}
                                        className="sf-arrow-wrapper"
                                        style={{
                                            position: "absolute",
                                            height: `${STEP_HEIGHT}px`,
                                            top: `${STEP_OFFSET + (index - 1) * STEP_HEIGHT}px`,
                                            left: 0,
                                            right: 0,
                                        }}
                                    >
                                        <div
                                            style={{
                                                height: `${STEP_HEIGHT}px`,
                                                width: "100px",
                                            }}
                                        >
                                            <svg width="100" height="217">
                                                <use
                                                    href="#left-curved-arrow"
                                                    x="0"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                ),
                        )}
                    </div>
                    <div className="sf-steps-column">
                        {steps.map((step, index) => (
                            <div key={index} className="sf-step-container">
                                <div className="sf-rectangle">
                                    <div className="sf-task-header-group">
                                        <div
                                            className="feature-item-sf"
                                            style={{ marginRight: "15px" }}
                                        >
                                            <img
                                                style={{
                                                    width: "35px",
                                                    height: "35px",
                                                    padding: "5px",
                                                    backgroundColor: "#FFF",
                                                    objectFit: "contain",
                                                }}
                                                src={step.icon}
                                            />
                                        </div>
                                        <div className="sf-step-container-title">
                                            {step.title}
                                        </div>
                                        <div style={{ width: "40px" }}></div>
                                    </div>
                                </div>
                                <div className="sf-step-content-container">
                                    <div className="sf-step-content">
                                        {step.content}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="sf-arrow-column hide-on-mobile-800">
                        {steps.map(
                            (_, index) =>
                                index % 2 !== 0 &&
                                index < steps.length && (
                                    <div
                                        key={index}
                                        className="sf-arrow-wrapper"
                                        style={{
                                            position: "absolute",
                                            height: `${STEP_HEIGHT}px`,
                                            top: `${STEP_OFFSET + (index - 1) * STEP_HEIGHT}px`,
                                            left: 0,
                                            right: 0,
                                        }}
                                    >
                                        <div
                                            style={{
                                                height: `${STEP_HEIGHT}px`,
                                                width: "100px",
                                            }}
                                        >
                                            <svg width="100" height="217">
                                                <use
                                                    href="#right-curved-arrow"
                                                    x="0"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                ),
                        )}
                    </div>
                </div>
                <button
                    className="apply-button"
                    onClick={() => navigate("/apply")}
                >
                    APPLY NOW
                </button>
            </div>
        </div>
    );
};

export default StepFlow;
