import { Route, Routes } from "react-router-dom";
import DesktopHomepage from "./components/website/Home";
import HowItWorks from "./components/website/HowItWorks";
import Pricing from "./components/website/Pricing";
import Apply from "./components/website/Apply";
import ApplyConfirmation from "./components/website/sections/ApplyConfirmation";

import React from "react";
import SVGSprite from "./assets/website/SVGSprite";
import { useMetaPixel } from "./components/shared/advertising/MetaPixel";
import useScriptLoader from "./components/shared/utils/ScriptLoading";
import { useTikTokPixel } from "./components/shared/advertising/TikTokPixel";

function Website() {
    useScriptLoader(
        "https://cdn.jsdelivr.net/npm/@statsig/js-client@3/build/statsig-js-client+session-replay+web-analytics.min.js?apikey=client-lSNE8hcT0NH0dtwmoO1NXB3lkiqa1vEHSQR4iiaRfRh",
    );
    useMetaPixel();
    useTikTokPixel();

    function SiteRoutes() {
        return (
            <>
                <SVGSprite />
                <Routes>
                    <Route path="/" element={<DesktopHomepage />} />
                    <Route path="/blog" element={<DesktopHomepage />} />
                    <Route path="/how-it-works" element={<HowItWorks />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/apply" element={<Apply />} />
                    <Route
                        path="/apply-confirmation"
                        element={<ApplyConfirmation />}
                    />
                </Routes>
            </>
        );
    }

    return <SiteRoutes />;
}

export default Website;
