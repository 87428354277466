import "../Home.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Arrow from "./Arrow";

const Exclusive = () => {
    const navigate = useNavigate();
    const [featureIndex, setFeatureIndex] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

    const features = [
        {
            title: "BY INVITATION ONLY",
            feature:
                "Our exclusive, application-only process ensures that " +
                "each student is committed to achieving their goals " +
                "with perseverance and passion. We welcome those who " +
                "are eager to be part of a supportive and results-driven community.",
        },
        {
            title: "STUDENT SELECTION CRITERIA",
            feature:
                "Our selection isn't based on your current " +
                "scores or past performances; rather, we value your " +
                "dedication, work ethic, and readiness to engage " +
                "actively in our comprehensive learning process.",
        },
        {
            title: "NO SCORE REQUIREMENTS",
            feature:
                "Whether you're starting from scratch or looking " +
                "to improve, we welcome students who are willing to show up and put " +
                "in the effort. At Alia, it's your commitment and willingness " +
                "to learn that matter the most.",
        },
    ];

    const incrementIndex = () => {
        setFeatureIndex((prevIndex) => (prevIndex + 1) % features.length);
    };

    const decrementIndex = () => {
        setFeatureIndex(
            (prevIndex) => (prevIndex - 1 + features.length) % features.length,
        );
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 800);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div className="as-container">
            <div className="as-content">
                <div className="as-title" style={{ paddingBottom: "40px" }}>
                    An Exclusive Platform for Motivated Students
                </div>
                <div
                    className="as-text"
                    style={{
                        textAlign: "center",
                        paddingLeft: "0px",
                        paddingBottom: "50px",
                    }}
                >
                    At Alia, we pride ourselves on being a referral and
                    invite-only organization, committed to delivering real
                    results. Our approach is designed for those who are
                    genuinely ready to invest in their future and embrace the
                    journey of learning.
                </div>
                <div className="info-boxes">
                    {isMobile ? (
                        <div className="info-boxes-mobile-content">
                            <div className="info-box">
                                <div className="info-header">
                                    {features[featureIndex].title}
                                </div>
                                <div className="info-text">
                                    {features[featureIndex].feature}
                                </div>
                            </div>
                            <div className="review-arrow-container">
                                <Arrow
                                    direction="left"
                                    onClick={() => decrementIndex()}
                                    className="hide-on-web-800"
                                />
                                <Arrow
                                    direction="right"
                                    onClick={() => incrementIndex()}
                                    className="hide-on-web-800"
                                />
                            </div>
                        </div>
                    ) : (
                        features.map((feature, index) => (
                            <div key={index} className="info-box">
                                <div className="info-header">
                                    {feature.title}
                                </div>
                                <div className="info-text">
                                    {feature.feature}
                                </div>
                            </div>
                        ))
                    )}
                </div>
                <button
                    className="apply-button"
                    onClick={() => navigate("/apply")}
                >
                    APPLY NOW
                </button>
            </div>
        </div>
    );
};

export default Exclusive;
