import React, { useEffect, useMemo } from "react";

const CalendlyEmbed = ({ settings, setSettings, bookAbility, handleApply }) => {
    const calendlyUrl = useMemo(() => {
        const url = new URL(
            "https://calendly.com/d/3pg-kpq-582/alia-education-intro-call",
        );
        url.searchParams.append("name", settings.userFirstName || "");
        url.searchParams.append("email", settings.userEmail || "");
        return url.toString();
    }, [settings.userFirstName, settings.userEmail]);

    useEffect(() => {
        const head = document.querySelector("head");
        const script = document.createElement("script");
        script.setAttribute(
            "src",
            "https://assets.calendly.com/assets/external/widget.js",
        );
        script.async = true;
        head.appendChild(script);

        const handleCalendlyEvent = (e) => {
            if (
                e.data.event &&
                e.data.event.indexOf("calendly.event_scheduled") === 0
            ) {
                const scheduledEventUri = e.data.payload.event.uri;
                setSettings({
                    ...settings,
                    callScheduled: true,
                });
                handleApply(scheduledEventUri);
            }
        };

        window.addEventListener("message", handleCalendlyEvent);

        return () => {
            head.removeChild(script);
            window.removeEventListener("message", handleCalendlyEvent);
        };
    }, [bookAbility]);

    const widgetStyles = {
        height: "700px",
        width: "100%",
    };

    return (
        <>
            {bookAbility && (
                <>
                    <div
                        className="calendly-inline-widget"
                        data-url={calendlyUrl}
                        style={widgetStyles}
                    />
                    {settings?.callScheduled && (
                        <p>Event booked successfully!</p>
                    )}
                </>
            )}
        </>
    );
};

export default CalendlyEmbed;
