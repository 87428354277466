import alia_logo_white from "../../assets/alia_logo_white.svg";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PersonalInfoContainer } from "../shared/settings/settings_pages/account_components/PersonalInfo";
import { TestInfoContainer } from "../shared/settings/settings_pages/account_components/TestInfo";
import CalendlyEmbed from "./sections/CalendlyEmbed";
import BottomBar from "./sections/BottomBar";
import GenericAsync from "../shared/backend/GenericAsync";
import { trackApplicationSubmission } from "../shared/advertising/MetaPixel";
import ConfirmModal from "../shared/popups/ConfirmModal";
import queryString from "query-string";

const Apply = () => {
    const navigate = useNavigate();
    const commitmentBlock = false;

    const [settings, setSettings] = useState({
        isCommitted: !commitmentBlock,
        callScheduled: false,
        textOptIn: true,
    });
    const [bookAbility, setBookAbility] = useState(false);
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const [popupModal, setPopupModal] = useState(false);
    const [failedSubmissionMessage, setFailedSubmissionMessage] = useState(
        "We're sorry, but your application was not successful. Please reach " +
            "out to our team for help.",
    );

    const handleApply = async (scheduledEventUri) => {
        let response = null;

        const eventId = crypto.randomUUID();
        const cookies = document.cookie.split("; ");
        const fbpCookie = cookies.find((cookie) => cookie.startsWith("_fbp="));
        const fbcCookie = cookies.find((cookie) => cookie.startsWith("_fbc="));
        const ttclidCookie = cookies.find((cookie) =>
            cookie.startsWith("ttclid="),
        );
        const ttpCookie = cookies.find((cookie) => cookie.startsWith("_ttp="));

        // Get the values of the cookies
        const fbp = fbpCookie ? fbpCookie.split("=")[1] : null;
        const fbc = fbcCookie ? fbcCookie.split("=")[1] : null;
        const ttclid = ttclidCookie ? ttclidCookie.split("=")[1] : null;
        const ttp = ttpCookie ? ttpCookie.split("=")[1] : null;

        // Get UTM parameters and other query parameters
        const queryParams = queryString.parse(window.location.search);
        const {
            utm_source,
            utm_medium,
            utm_campaign,
            utm_content,
            utm_term,
            fbclid,
            gclid,
            ...otherParams
        } = queryParams;

        try {
            response = await GenericAsync({
                backendUrl: `${backendUrl}/api/apply-for-account`,
                dataToSend: {
                    eventId: eventId,
                    scheduledEventUri: scheduledEventUri,
                    // Facebook parameters
                    fbc: fbc || null,
                    fbp: fbp || null,
                    fbclid: fbclid || null,
                    // TikTok parameters
                    ttclid: ttclid || null,
                    ttp: ttp || null,
                    // Google parameter
                    gclid: gclid || null,
                    // UTM parameters
                    utm_source: utm_source || null,
                    utm_medium: utm_medium || null,
                    utm_campaign: utm_campaign || null,
                    utm_content: utm_content || null,
                    utm_term: utm_term || null,
                    // Other data
                    otherParams,
                    settings,
                    referrer: document.referrer || null,
                    userAgent: navigator.userAgent || null,
                },
            });
            if (response.success) {
                trackApplicationSubmission(settings.userEmail, eventId);
                navigate("/apply-confirmation");
            } else {
                setFailedSubmissionMessage(response.message);
                setPopupModal(true);
            }
        } catch (error) {
            console.error(
                "An error occurred during application:",
                error.message,
            );
        }
    };

    useEffect(() => {
        const requiredSettings = [
            settings?.userFirstName,
            settings?.userEmail,
            settings?.userLastName,
            settings?.focus,
            settings?.goalScore,
            settings?.testDate,
            settings?.phoneNumber,
        ];

        const allSettingsDefined = requiredSettings.every(
            (setting) =>
                setting !== undefined &&
                setting !== null &&
                setting !== "" &&
                setting !== "none" &&
                setting !== false,
        );

        setBookAbility(allSettingsDefined);
    }, [settings]);

    return (
        <div className="ws-home-page" style={{ backgroundColor: "#016dea" }}>
            <div className="apply-content">
                <div className="apply-logo-container">
                    <img
                        src={alia_logo_white}
                        style={{
                            maxWidth: "100px",
                            maxHeight: "50px",
                        }}
                        alt="Alia Logo"
                        className="apply-logo"
                        onClick={() => navigate("/")}
                    />
                </div>
                <div
                    className="as-title"
                    style={{
                        padding: "30px 0 30px 0",
                        margin: "0 auto",
                    }}
                >
                    Apply Now
                </div>
                <div className="apply-banner-text">
                    Alia is reserved for a select number of dedicated students
                    ready to reach their goal score. To apply for Alia&apos;s
                    personalized platform and expert tutors, please fill out and
                    submit the application below.
                </div>
                <div className="apply-settings-block">
                    <div className="apply-settings-block-title">
                        01. Fill Out Your Personal Info
                    </div>
                    <PersonalInfoContainer
                        settings={settings}
                        setSettings={setSettings}
                        fixedEmail={false}
                        applyPage={true}
                        optIntoText={true}
                    />
                </div>
                <div className="apply-settings-block">
                    <div className="apply-settings-block-title">
                        02. Fill Out Your Test Info
                    </div>
                    <TestInfoContainer
                        settings={settings}
                        setSettings={setSettings}
                        applyPage={true}
                    />
                </div>
                <div className="apply-settings-block">
                    <div className="apply-settings-block-title">
                        03. Schedule Your Intro Call
                    </div>
                    <div className="calendly link">
                        <CalendlyEmbed
                            settings={settings}
                            setSettings={setSettings}
                            bookAbility={bookAbility}
                            handleApply={handleApply}
                        />
                    </div>
                </div>
            </div>
            <BottomBar applyPage={true} />
            <ConfirmModal
                isOpen={popupModal}
                onConfirm={() => setPopupModal(false)}
                message={
                    <div style={{ justifyContent: "center" }}>
                        <div
                            style={{
                                color: "#016dea",
                                fontSize: "24px",
                                marginBottom: "20px",
                            }}
                        >
                            Unsuccessful Application
                        </div>
                        {failedSubmissionMessage}
                    </div>
                }
                confirmMessage={"Go Back"}
            ></ConfirmModal>
        </div>
    );
};

export default Apply;
