import { useEffect } from "react";

export const useTikTokPixel = () => {
    useEffect(() => {
        // Create a new script element
        const script = document.createElement("script");
        script.innerHTML = `
            !function (w, d, t) {
              w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
            var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
            ;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
            
            
              ttq.load('CRTVODRC77U61CV1IFHG');
              ttq.page();
            }(window, document, 'ttq');
        `;
        document.head.appendChild(script);

        // Check if the script loaded successfully
        const checkScriptLoaded = () => {
            if (
                !(
                    typeof window.ttq === "object" &&
                    typeof window.ttq.page === "function"
                )
            ) {
                console.warn(
                    "TikTok Pixel failed to load. It might be blocked by an ad-blocker.",
                );
            }
        };

        // Set a timeout to check if the script loaded
        const timeoutId = setTimeout(checkScriptLoaded, 2000); // Check after 2 seconds

        // Cleanup function
        return () => {
            clearTimeout(timeoutId);
            if (script.parentNode) {
                script.parentNode.removeChild(script);
            }
        };
    }, []);
};
