import React, { useState } from "react";
import "../Home.css";
import { ChevronDown, ChevronUp } from "lucide-react";

const FAQ = () => {
    const [openDropdown, setOpenDropdown] = useState(null);

    const toggleDropdown = (index) => {
        setOpenDropdown(openDropdown === index ? null : index);
    };

    const faqItems = [
        {
            title: "WHAT MAKES ALIA DIFFERENT?",
            content:
                "At Alia, we deliver only tried and true methods of learning. This " +
                "means check-ins with expert tutors who can guide you on your study " +
                "journey; high-quality content that closely matches what you will see " +
                "on test day; and a student-first approach that ignores unproven or " +
                "ineffective solutions like AI chat bots. Given this focus, " +
                "we’ve designed our education platform to be a high-touch, " +
                "high-intensity solution that requires buy-in from its users from the " +
                "start.",
        },
        {
            title: "I'M NOT SURE ALIA IS RIGHT FOR ME, WHAT SHOULD I DO?",
            content:
                "If you’re not sure whether you’re ready to take the plunge with " +
                "Alia, we recommend you reach out to us. We’d be happy to discuss the " +
                "particulars of your situation, and help you determine whether the Alia " +
                "Bootcamp is a solution that will work for you. ",
        },
        {
            title: "WHY IS ALIA MORE EXPENSIVE THAN OTHER SAT SUBSCRIPTIONS?",
            content:
                "At $275/month, Alia is indeed more expensive than other online " +
                "platforms, but still a " +
                "fraction of the cost of expert tutoring services. " +
                "Our offering is not merely another " +
                "subscription — we view it as a high-intensity, outcome-oriented " +
                "bootcamp designed to get you your goal score as soon as possible. " +
                "As part of " +
                "this service, we offer check-ins with expert tutors alongside " +
                "best-in-class content, a combination you will not find " +
                "elsewhere in the test prep space at any price point.",
        },
        {
            title: "HOW DO I GET STARTED?",
            content:
                "Please review the " +
                '<a href="/how-it-works" style="color: white; text-decoration: underline;">How It Works</a> ' +
                "section of our website and then " +
                "apply. If you’re a good fit for our service and we have room to " +
                "accept new applicants, we’ll set you up with an account. Once you’re " +
                "in, you’ll be able to start your SAT study journey with us immediately. ",
        },
        {
            title: "HOW CAN I GET IN TOUCH WITH ALIA?",
            content:
                "We recommend reaching out via the orange Intercom icon in the " +
                "bottom right of the screen. We pride ourselves on being a highly " +
                "responsive team, and this way, we’ll see your message immediately " +
                "and will get back to you as soon as possible.",
        },
        {
            title: "I WASN'T ACCEPTED AS AN ALIA LEARNER, WHAT CAN I DO NEXT?",
            content:
                "We’re sorry that we weren’t able to accept you! If this is the " +
                "case, it’s because we’re limited in the number of new students we can " +
                "accept given the level of service we want to provide to all Alia " +
                "users. Don’t worry though — we'll hold onto your application and " +
                "get you started with our SAT prep service as soon as we’re able to " +
                "accept new students. ",
        },
        {
            title: "I'M A TUTOR WHO'S LOOKING TO USE ALIA'S CONTENT, WHAT SHOULD I DO?",
            content:
                "If you’re a tutor looking to leverage Alia’s best-in-class study " +
                "content, we recommend you reach out via the Intercom icon on the " +
                "bottom right of the page. We’ll then set up a call to see how " +
                "Alia’s content can be helpful to you and your students.",
        },
        {
            title: "IS THE ALIA PLATFORM MOBILE ACCESSIBLE?",
            content:
                "The Alia Platform is indeed mobile accessible via browser " +
                "(we recommend Chrome or Safari). We recommend that you primarily " +
                "interact with the Alia Education platform through your desktop, as " +
                "this will contain all our site features and will be most similar to " +
                "how you will see the material on test day. However, if you’re on the " +
                "go, the platform will work just fine on your phone. ",
        },
        {
            title: "WHY DOES THE SAT MATTER?",
            content:
                "In an uncertain time for college admissions, the SAT remains a widely accepted " +
                "standard for assessing student capabilities. Across the US, the SAT " +
                "is returning as an application requirement, and high scores " +
                "significantly boost acceptance rates at any college. Moreover, if " +
                "you’re a student coming from a high school with fewer " +
                "extra-curricular or college preparation programs, the SAT can be one " +
                "of the only ways to communicate your talent and potential to " +
                "admissions officers.",
        },
    ];

    return (
        <div className="as-container">
            <div className="as-content">
                <div className="as-title">FREQUENTLY ASKED QUESTIONS</div>
                <div
                    className="as-right-container"
                    style={{
                        width: "95%",
                        maxWidth: "1000px",
                        paddingTop: "20px",
                    }}
                >
                    {faqItems.map((item, index) => (
                        <div key={index} className="as-dropdown">
                            <div
                                className="as-dropdown-header"
                                onClick={() => toggleDropdown(index)}
                            >
                                <div className="as-dropdown-text">
                                    {item.title}
                                </div>
                                <div style={{ width: "32px" }}>
                                    {openDropdown === index ? (
                                        <ChevronUp size={32} color="#EC5725" />
                                    ) : (
                                        <ChevronDown
                                            size={32}
                                            color="#FFAB24"
                                        />
                                    )}
                                </div>
                            </div>
                            {openDropdown === index && (
                                <div
                                    className="as-text"
                                    style={{
                                        paddingLeft: "20px",
                                        boxSizing: "border-box",
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: item.content,
                                    }}
                                ></div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FAQ;
